/* eslint-disable global-require */
const flatten = require('flat')
const languages = require('../languages')

let messages = {}
languages.forEach(
  ({ value }) => (messages[value] = flatten(require('./' + value + '.json')))
)

// Remove falsy values ( or would overwrite English values in getMessages )
Object.values(messages).forEach(langMessages => {
  Object.entries(langMessages).forEach(([key, message]) => {
    if (!message) {
      delete langMessages[key]
    }
  })
})

module.exports = messages
