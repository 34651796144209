import React from 'react'
import { string, node } from 'prop-types'
import Link from 'gatsby-link'
import { injectIntl, intlShape } from 'react-intl'

const I18nLink = ({ to, children, intl, ...rest }) => (
  <Link to={`/${intl.locale}${to}`} {...rest}>
    {children}
  </Link>
)

I18nLink.propTypes = {
  to: string.isRequired,
  children: node.isRequired,
  intl: intlShape.isRequired,
}

export default injectIntl(I18nLink)
