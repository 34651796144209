// don't use es modules because it's used in gatsby-node.js
module.exports = [
  { value: 'da', text: 'Dansk' },
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Español' },
  { value: 'it', text: 'Italiano' },
  { value: 'ja', text: '日本語' },
  { value: 'ko', text: '한국어' },
  { value: 'pt-BR', text: 'Português (Brasil)' },
  { value: 'ru', text: 'Pусский' },
  { value: 'vi', text: 'Tiếng Việt', hideOnFooter: true },
  { value: 'zh-CN', text: '中文 (简体)' },
]
