// maps site lang to blog lang
const blogLangs = {
  en: 'en',
  es: 'es',
  ko: 'ko',
  ja: 'ja',
  'zh-CN': 'zh',
  it: 'it',
}

export default blogLangs
