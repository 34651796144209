import Helmet from 'react-helmet'
import React from 'react'

const SocialTags = ({ pageTitle, description, metaImgUrl }) => (
  <Helmet>
    {/* Primary Meta Tags */}
    <title>{pageTitle}</title>
    <meta name="title" content={pageTitle} />
    <meta name="description" content={description} />

    {/*  Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="[https://makerdao.com/en](https://makerdao.com/en)"
    />
    <meta property="og:title" content={pageTitle} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={metaImgUrl} />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta
      property="twitter:url"
      content="[https://makerdao.com/en](https://makerdao.com/en)"
    />
    <meta property="twitter:title" content={pageTitle} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={metaImgUrl} />
  </Helmet>
)

export default SocialTags
